.loader {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: fixed;
    width: 100%;
    z-index: 999999999999;
    background: #fcfcfc url("http://www.mvgen.com/loader.gif") no-repeat scroll
      center center / 120px 120px;
    top: 0;
    left: 0;
    opacity: 1;
  }